import React from 'react';
import { motion } from 'framer-motion';
import DonationSharing from './DonationSharingMediumLists.js';
import PropTypes from 'prop-types';

const DonationShareModal = ({
  handleCloseModal,
  handleCopyLink,
  canonicalUrl,
}) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-5 z-50 flex items-center justify-center">
      <div className="absolute bottom-61 lg:bottom-11 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
        <div className="w-full flex justify-center">
          <div className="max-w-md w-full rounded-2xl p-6 lg:p-8 bg-neutral">
            <div className="flex flex-col">
              <div className="flex justify-between w-full">
                <p className="font-bold text-xl lg:text-[22px] leading-[1.5] lg:leading-[1.55]">
                  Be A Changemaker
                </p>
                <button onClick={handleCloseModal}>
                  <img src="/icons/cancel-icon.svg" alt="cancel icon" />
                </button>
              </div>

              <p className="mt-2 text-base tracking-[0.2px] font-normal">
                Your share will help Muslims better understand Islam.
              </p>
            </div>
            <div className="mt-6">
              <DonationSharing canonicalUrl={canonicalUrl} />
            </div>
            <div className="mt-6 flex items-center rounded-[6px] justify-between w-full border border-white-1">
              <p className="ml-4 text-base tracking-[0.2px]">
                https://gtaf.org/donate/
              </p>
              <motion.button
                onClick={handleCopyLink}
                className=" my-2 mr-2 "
                whileHover={{ scale: 1.1 }}
                aria-label="Copy link to clipboard"
              >
                <button className="bg-white-3 rounded-[6px] px-3 pb-2 pt-[6px] text-base tracking-[0.2px]">
                  Copy
                </button>
              </motion.button>
            </div>
            <div className="mt-6 py-2 pr-2 flex">
              <img
                className="h-8"
                src="/icons/quote-down.svg"
                alt="quotation mark"
              />
              <div className="ml-2 text-base tracking-[0.2px]">
                “Whoever guides someone to goodness will have a reward like the
                one who did it.” <br />
                <br />- Prophet Muhammad ﷺ
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
DonationShareModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleCopyLink: PropTypes.func.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
};
export default DonationShareModal;
