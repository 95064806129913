import PropTypes from 'prop-types';
import React from 'react';
import { motion } from 'framer-motion';
import { socialLinksWithIcon as socialLinks } from '../constants';

const DonationInfoSharing = ({ canonicalUrl }) => {
  return (
    <div className="flex gap-3">
      {socialLinks.map((link) => (
        <a
          key={link.url}
          href={link.url + canonicalUrl}
          target="_blank"
          rel="noreferrer"
        >
          <motion.div
            className=" border border-white-1 p-2 lg:p-4 rounded"
            whileHover={{ scale: 1.1 }}
          >
            <img
              className="w-8 h-8"
              src={`/icons/${link.icon}.svg`}
              alt="Logo"
            />
          </motion.div>
        </a>
      ))}
    </div>
  );
};

DonationInfoSharing.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};

export default DonationInfoSharing;
