import React from 'react';
import { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout';
import PropTypes from 'prop-types';
import { CommonContext } from '../../context';
import { useToast } from '../../hooks/useToast';
import DonationShareModal from '../../components/DonationSharingModal.js';
import FeaturedAppsCardBg from '../../../static/images/featured_apps-card_bg-icon-without-padding.svg';
import { copyToClipboard } from '../../utils';
export const query = graphql`
  query {
    allMessages {
      nodes {
        donation {
          success
        }
      }
    }
  }
`;
export default function DonationSuccess({ location }) {
  const canonicalUrl = 'https://gtaf.org/donate/';
  const { addToast } = useToast();
  const handleCopyLink = async () => {
    copyToClipboard(canonicalUrl, addToast);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShareClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Donation Successful" location={location}>
        <div className="relative">
          <section className="w-full flex justify-center bg-primary-0">
            <div className="flex flex-col my-24 max-w-xl">
              <img
                className="mx-auto"
                src="/icons/success-icon.svg"
                alt="Donation Success Icon"
              />
              <div className="mt-11 mx-6 lg:mx-0">
                <p className="leading-[1.25] tracking-[-0.3px]  font-extraBold text-center text-4xl lg:text-5xl lg:tracking-[-0.4px]">
                  {' '}
                  JazakAllahu Khair!
                </p>
                <p className="font-3 mt-3 text-center text-sm leading-[1.72] lg:text-base lg:leading-[1.625] lg:tracking-[-0.1px]">
                  {' '}
                  Your contribution is making a difference Alhamdulillah. May
                  Allah
                  <span className="hidden font-0 text-[0] lg:block leading-none">
                    <br />
                  </span>{' '}
                  accept it and multiply its rewards, Ameen.
                </p>
              </div>
            </div>
          </section>

          <section className="w-full flex justify-center">
            <div className="flex flex-col w-full my-16 lg:my-24 mx-6 lg:max-w-6xl lg:mx-0 ">
              <div className="flex flex-col lg:flex-row rounded-3xl overflow-hidden border border-white-1 ">
                <div className="ml-8 lg:ml-12 my-8 pr-8 lg:pr-16">
                  <p className="text-ash-3 font-medium leading-[1.5] text-xl lg:text-2xl">
                    Be A Changemaker!
                  </p>
                  <p className="mt-1 font-extraBold lg:font-bold text-3xl leading-[1.25] lg:leading-[1.5] tracking-[-0.7px]">
                    Your Share Will Bring Others Closer <br />
                    To Allah
                  </p>
                  <button
                    onClick={handleShareClick}
                    className="flex shadow-none hover:shadow-lg items-center mt-4 px-7 py-3 justify-center text-sm rounded-3xl bg-primary text-neutral lg:text-base"
                  >
                    <p className="mx-2 font-semibold ">Share</p>
                    <img
                      className=""
                      src="/icons/share_icon.svg"
                      alt="Donation Success Icon"
                    />
                  </button>
                </div>
                <div className="relative ml-auto mt-10">
                  <img
                    src={FeaturedAppsCardBg}
                    alt="feature app card bg"
                    className=" w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="mt-16 text-base text-center text-normal font-bold text-primary leading-[1.625] tracking-[-0.1px]">
                <Link to="/">Go Back to homepage</Link>
              </div>
            </div>
          </section>
          {isModalOpen && (
            <DonationShareModal
              handleCloseModal={handleCloseModal}
              handleCopyLink={handleCopyLink}
              canonicalUrl={canonicalUrl}
            />
          )}
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

DonationSuccess.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
